import "./404.scss"

import React from "react"

import { navigate } from "gatsby"
import { GatsbyImage, StaticImage } from "gatsby-plugin-image"

import Button from "../components/Button"
import Dash from "../components/Dash"
import Layout from "../components/Layout"
import cardBgLeft from "../images/404/left-shape.webp"
import {
  getCurrentLanguagePage,
  getGatsbyImage,
  reformatCta,
} from "../plugins/common"
import { CustomMarkdown } from "../plugins/sanitizehtml"

const simplePageShape_left = "../images/simplePage/simplePageShape_left.webp"
const simplePageShape_right = "../images/simplePage/simplePageShape_right.webp"

const Page404 = ({ pageContext, location }) => {
  const { data, general, form } = pageContext
  const languageOptions = general.allStrapiLanguages?.nodes ?? "en"
  const lang = getCurrentLanguagePage(location, languageOptions)
  const aleaToken = general.aleaToken
  const { title, subtitle, content, image, cta, goBackLabel, meta } = data
  const enableFooterForm = data.footerForm?.enableFooter
  const footerForm = {
    ...data.footerForm,
    ...form.freeQuote,
  }

  //redirections
  if (location.pathname === "/resources/at-home-covid-19-antibodies-test") {
    navigate("/resources/at-home-covid-19-rapid-test")
  }

  const homeBtn = reformatCta(cta)

  return (
    <Layout
      props={general}
      meta={meta}
      location={location}
      lang={lang}
      token={aleaToken}
      footerForm={enableFooterForm ? footerForm : null}
    >
      <div className="page404">
        <div className="container-fluid bg-daffodil text-center header position-relative">
          <StaticImage
            sizes="(max-width: 370px) 37w, (max-width: 500px) 50vw,(max-width: 800px) 80vw,  100vw"
            className="left position-absolute"
            src={simplePageShape_left}
            alt=""
            layout="constrained"
          />
          <StaticImage
            sizes="(max-width: 370px) 37w, (max-width: 500px) 50vw,(max-width: 800px) 80vw,  100vw"
            className="right position-absolute"
            src={simplePageShape_right}
            alt=""
            layout="constrained"
          />
          <h1 className="ming">{title}</h1>
        </div>
        <div
          className="container content card position-relative"
          style={{ backgroundImage: `url(${cardBgLeft})` }}
        >
          <div className="row">
            <div className=" col-lg-6 order-lg-0 order-1 text-center">
              <GatsbyImage
                sizes="(max-width: 370px) 37w, (max-width: 500px) 50vw,(max-width: 800px) 80vw,  100vw"
                className="image"
                image={getGatsbyImage(image)}
                alt={image.alternativeText}
              />
            </div>
            <div className="col-lg-6 order-lg-1 order-0">
              <h2 className="subtitle text-lg-start text-center">{subtitle}</h2>
              <Dash colorClass="bg-copper" />
              <CustomMarkdown html={content}></CustomMarkdown>
            </div>
          </div>
          <div className="row">
            <div className="offset-lg-6 col-lg-6 d-flex flex-lg-row flex-column align-items-lg-start align-items-center">
              <div
                className="back"
                role="button"
                tabIndex={0}
                onClick={() => navigate(-1)}
                onKeyDown={() => navigate(-1)}
              >
                <Button lang={lang} text={goBackLabel} type="primary" />
              </div>
              <Button
                lang={lang}
                text={homeBtn.buttonLabel}
                type={homeBtn.template}
                url={homeBtn.url}
              />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}
export default Page404
